import styled, { css } from 'styled-components';
import { fontSize, mediaDown } from './mixins';
import { colors, grayScales } from './variables';

const PageBodyStyled = styled.div`
  .page__body--content {
    line-height: 1.7;

    h1 {
      ${fontSize(28)}
      font-weight: bold;
      padding: 15px;
      background-color: ${grayScales.gs300};
      margin-bottom: 2rem;

      ${mediaDown(
        'desktop',
        css`
          ${fontSize(20)}
          padding: 12px;
        `
      )}
    }

    h2 {
      ${fontSize(24)}
      padding-bottom: 10px;
      margin-top: 2.4rem;
      margin-bottom: 1rem;
      position: relative;

      ${mediaDown(
        'desktop',
        css`
          ${fontSize(18)}
        `
      )}

      &::after {
        content: "";
        display: block;
        width: 1em;
        height: 2px;
        background-color: ${grayScales.gs400};
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    h3 {
      ${fontSize(20)}
      margin-top: 2rem;
      margin-bottom: 1rem;

      &.subheader {
        padding-left: 15px;
        border-left: 5px solid ${colors.primary};
        font-weight: 600;
      }

      ${mediaDown(
        'desktop',
        css`
          ${fontSize(16)}
        `
      )}
    }

    h4 {
      ${fontSize(18)}
      margin-top: 2rem;
      margin-bottom: 1rem;

      ${mediaDown(
        'desktop',
        css`
          ${fontSize(14)}
        `
      )}
    }

    h5 {
      ${fontSize(16)}
      margin-top: 2rem;
      margin-bottom: 1rem;

      ${mediaDown(
        'desktop',
        css`
          ${fontSize(14)}
        `
      )}
    }

    h6 {
      ${fontSize(14)}
      margin-top: 2rem;
      margin-bottom: 1rem;

      ${mediaDown(
        'desktop',
        css`
          ${fontSize(12)}
        `
      )}
    }

    /* 段落 */
    p {
      margin-bottom: 2rem;
    }

    /* 強調・斜体 */
    strong {
      font-weight: bold;
    }

    em {
      color: #f00;
    }

    /* 引用 */
    blockquote {
      position: relative;
      padding-left: 2rem;

      &::after {
        content: "";
        display: block;
        width: 3px;
        background-color: #91919e;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
      }
    }

    /* 水平線 */
    hr {
      margin: 2rem auto;
      border: none;
      background-color: #91919e;
      height: 1px;
    }

    /* リスト */
    ul {
      margin-bottom: 2rem;
      list-style-type: disc;
      padding-left: 1.7em;
    }

    ol {
      margin-bottom: 2rem;
      list-style-type: decimal;
      padding-left: 1.4em;
    }

    dl {
      margin-bottom: 2rem;

      dt {
        font-weight: bold;
      }

      dd {
        margin-left: 1.7rem;
      }
    }

    /* コードブロック */
    pre {
      color: ${colors.white};
      margin-bottom: 2rem;
      padding: 2rem 2rem 0;
      background-color: #91919e;
    }

    /* リンク */
    a {
      color: #0086d3;
      text-decoration: underline;
    }

    /* テーブル */
    table {
      box-sizing: border-box;
      border-collapse: collapse;
      width: 100%;
      line-height: 1.4;
      text-align: center;
      margin-bottom: 2rem;

      th,
      td {
        border: 1px solid #dfdfe6;
        vertical-align: middle;
        padding: 12px 24px;
        text-align: left;
      }

      th {
        white-space: nowrap;
        background-color: ${grayScales.gs100};
        font-weight: bold;
      }
    }
  }

  /* FAQページ */
  .c-media {
    display: flex;
  }

  .c-icon {
    display: inline-block;

    &--faq {
      ${fontSize(20)}

      &-q {
        color: ${colors.primary};
        font-weight: 700;

        &::after {
          content: 'Q.';

        }
      }

      &-a {
        color: ${colors.danger};

        &::after {
          content: 'A.';
        }
      }
    }
  }

  .p-help-faq {
    &__body {
      ${fontSize(16)}
      line-height: 1.5;
      margin-bottom: 42px;

      ${mediaDown(
        'desktop',
        css`
          margin-bottom: 21px;
        `
      )}
    }
  
    &-content {
      margin-bottom: 15px;
  
      &__header {
        ${fontSize(24)}
        font-weight: bold;
        padding: 18px;
        background-color: ${grayScales.gs300};
        border-radius: 2px;

        ${mediaDown(
          'desktop',
          css`
            ${fontSize(18)}
            padding: 15px;
          `
        )}
      }
    }
  
    &-list {
      &__item {
        border-bottom: 1px solid ${grayScales.gs300};
        padding: 16px 0;
  
        &:last-of-type {
          border-bottom: none;
        }
      }
  
      &-content {
        .c-media {
          align-items: center;
  
          ${mediaDown(
            'desktop',
            css`
              align-items: flex-start;
            `
          )}
  
          &__image {
            padding-right: 0;
            width: 36px;

            ${mediaDown(
              'desktop',
              css`
                width: 28px;
                padding-top: 0.25rem;
              `
            )}
          }
  
          &__body {
            ${fontSize(20)}
            flex: 1;
            line-height: 1.5;

            ${mediaDown(
              'desktop',
              css`
                ${fontSize(16)}
              `
            )}

            p {
              margin-bottom: 0;
            }
          }
        }
  
        &--answer {
          margin-top: 1.5rem;
          padding-left: 36px;

          ${mediaDown(
            'desktop',
            css`
              padding-left: 28px;
            `
          )}
  
          .c-media {
            &__body {
              ${fontSize(16)}

              ${mediaDown(
                'desktop',
                css`
                  ${fontSize(14)}
                `
              )}
            }
          }
        }
      }
    }
  }

  .embed-pdf-link {
    display: none;

    ${mediaDown(
    'desktop',
    css`
      display: inline;
    `
  )}

    &__name {
      margin-bottom: 5px;
    }

    a {
      color: ${colors.white};
      text-decoration: none;
    }
  }

  .embed-pdf-iframe {
    ${mediaDown(
    'desktop',
    css`
      display: none;
    `
  )}
  }
`;

export default PageBodyStyled;