import FlatButton from 'components/atoms/button/FlatButton';
import styled from 'styled-components';

const NavigationActionsStyled = styled.div`
  .article-footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;

    button:first-child,
    a:first-child {
      margin-bottom: 30px;
    }
  }
`;

const NavigationActions = (props: {
  backTitle?: string;
  backPath: string;
  adminUrl: string | null;
}) => (
  <NavigationActionsStyled>
    <div className='article-footer'>
      <FlatButton
        type='default'
        title={props.backTitle || '一覧に戻る'}
        path={props.backPath}
      />
      {props.adminUrl &&
        <FlatButton
          type='primary'
          title='管理画面で編集'
          path={props.adminUrl}
        />
      }
    </div>
  </NavigationActionsStyled>
)

export default NavigationActions;